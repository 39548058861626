<template>
    <el-dialog width="800px" top="40px" title="Granska försättsblad" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <div class="border border-indigo-600">
            <!-- <div class="flex justify-end pb-20 pt-4 pr-4">
                <p v-if="coverSheetDetails.addFirstPageNumber">1 (1)</p>
            </div> -->
            <div class="flex justify-center">
                <div class="flex flex-col text-center py-32">
                    <p class="font-bold mb-2 text-lg">Årsredovisning</p>
                    <p class="font-bold mb-2 text-sm">för</p>
                    <p class="font-bold mb-2 text-lg">{{ this.clientInfo.name }}</p>
                    <p class="font-bold mb-8 text-sm">{{ this.clientInfo.registrationNumber }}</p>
                    <p>Räkenskapsåret</p>
                    <p>{{ this.clientInfo.financialYearStart }} - {{ this.clientInfo.financialYearEnd }}</p>
                </div>
            </div>
            <div v-if="coverSheetDetails.addTableOfContents" class="pl-10 py-8">
                <h1 class="font-bold mb-2">Innehållsförteckning</h1>
                <div v-for="(item, index) in coverSheetDetails.tableOfContents" :key="index" class="flex flex-row">
                    <div class="flex flex-col pr-6 w-60">
                        <p>{{ item.title }}</p>
                    </div>
                    <div class="flex flex-col">
                        <p>{{ item.pageNumber }}</p>
                    </div>
                </div>
            </div>
            <div v-else class="pl-10 py-20">
                <!-- No content -->
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="default">Stäng</el-button>
        </span>
    </el-dialog>
</template>

<script>
export default {
    props: {
        visible: {
            type: Boolean,
            default: false,
        },
        clientId: {
            type: String,
        },
        clientInfo: {
            type: Object,
        },
        coverSheetDetails: {
            type: Object,
        },
    },

    data() {
        return {
            formData: {},
            accountsPlanTypes: [],
        };
    },

    methods: {
        async create() {
            console.log("Clientid", this.clientId);
        },
        resetModalState() {
            this.formData = {};
        },

        closeModal() {
            this.$emit("close");
            console.log("Clientid", this.clientId);
            console.log("ClientInfo", this.clientInfo);
            console.log("registrationNumber", this.clientInfo.registrationNumber);
            console.log("coverSheetDetails", this.coverSheetDetails);
            console.log("tableOfContents", this.coverSheetDetails.tableOfContents);
        },
    },
};
</script>
